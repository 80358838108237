import React from "react";
import { Grid } from "@nodeme/grid-react";
import logo1 from "../assets/STD4.png";
import logo2 from "../assets/STD5.png";
import logo3 from "../assets/STD6.png";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

const styles = (theme: ITheme) => (definitions: IDefinitions) => {
  return {
    logo: {
      maxWidth: "100%",
    },
    logoHolder: {
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    logoHolder2: {
      paddingTop: "16px",
      textAlign: "center",
    },
    logoHolder3: {
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
  };
};

function Footer() {
  const classes = buildClasses(styles);
  return (
    <footer>
      <Grid spacing={8} vertical>
        <Grid item md={10 / 3}>
          <div className={classes.logoHolder}>
            <img
              src={logo1}
              className={classes.logo}
              alt="wanna see your work"
            />
          </div>
        </Grid>
        <Grid item md={10 / 3}>
          <div className={classes.logoHolder2}>
            <img src={logo2} className={classes.logo} alt="ON THE COVER" />
          </div>
        </Grid>
        <Grid item md={10 / 3}>
          <div className={classes.logoHolder3}>
            <img
              src={logo3}
              className={classes.logo}
              alt="Austrians leading newspaper DER STANDARD"
            />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
