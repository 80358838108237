import React from "react";
import { Container } from "@nodeme/grid-react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Container notFluid>
        <Header />
        <Body />
        <Footer />
      </Container>
    </div>
  );
}

export default App;
