import React from "react";
import { Grid } from "@nodeme/grid-react";
import newsPaper1 from "../assets/zeitung01.jpg";
import newsPaper2 from "../assets/zeitung02.jpg";
import newsPaper3 from "../assets/zeitung03.jpg";
import newsPaper4 from "../assets/zeitung04.jpg";

import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  link: {
    textDecoration: "none",
    color: "black",
  },
  sample: {
    textAlign: "right",
    fontWeight: 700,
    fontSize: "0.8rem",
  },
  example: {
    maxWidth: "100%",
  },
  exampleAligner: {
    textAlign: "right",
  },
  exampleHolder: {
    display: "inline-block",
    width: "200px",
  },
});

function Body() {
  const classes = buildClasses(styles);
  return (
    <main>
      <Grid spacing={8} vertical>
        <Grid item md={20 / 3}>
          <p className={classes.logoHolder}>
            Are you an NFT artist and want to be on the cover of DerSTANDARD?
            Submit your artwork now!
            <br />
            <br />
            DerSTANDARD, Austria’s leading quality newspaper with a coverage of
            more than half a million readers will issue it’s 10.000 edition on
            Jan 22, 2022.
            <br />
            DerSTANDARD has a tradition of having their anniversary covers
            designed by artists like Christian Ludwig Attersee in 2008.
            <br />
            <br />
            Main topic will be the questions “How will media be consumed in the
            future? What will they look like?” <br />
            Artworks can include the STANDARD logo and should refer to the
            anniversary number 10.000.
            <br />
            <br />
            Copyright for commercial use would be claimed by DerSTANDARD Your
            artwork may be submitted until Dec. 28, 2021, a jury will select one
            of the submitted artworks which will be auctioned for charitable
            purpose.
            <br />
            <br />
            Please contact us to receive your submission credentials:{" "}
            <a className={classes.link} href="mailTo:office@artcare.at">
              <b>office@artcare.at</b>
            </a>
          </p>
        </Grid>
        <Grid item md={10 / 3}>
          <div className={classes.sample}>
            <p>Example DerSTANDARD Cover 2008 by Christian Ludwig Attersee</p>
            <div className={classes.exampleAligner}>
              <div className={classes.exampleHolder}>
                <Grid spacing={8} root={{ top: 0, left: 0, right: 0 }} vertical>
                  <Grid item xs={5}>
                    <img
                      src={newsPaper1}
                      className={classes.example}
                      alt="example 1"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={newsPaper2}
                      className={classes.example}
                      alt="example 2"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={newsPaper3}
                      className={classes.example}
                      alt="example 3"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={newsPaper4}
                      className={classes.example}
                      alt="example 4"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </main>
  );
}

export default Body;
